import React from 'react';
import { graphql } from 'gatsby';
import styles from './get_in_touch.module.css';
import { Modal, Row, Col, Container, Form, Button } from 'react-bootstrap';
import { getLocaleWiseLabel as t } from '../../../lib/localeUtils';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import clientConfig from '../../../../client-config';
import SvgIcons from '../CompanyPage/SvgIcons';
import companyDetails from '../../companyDetails';
import { sendGetInTouchMessage } from '../../../lib/getInTouchMessageHandler';
import TEXTS from './hebrewTexts';

type AppProps = {
  labelArr: Array<object>;
};

const GetInTouchForm = ({ labelArr }: AppProps) => {
  const { register, handleSubmit, reset } = useForm<{ name: string; mail: string; content: string }>();
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);

  const onSubmit = async (data: { name: string; mail: string; content: string }) => {
    if (data.name && data.mail && data.content) {
      setIsButtonDisabled(true);
      await sendGetInTouchMessage(data.name, data.mail, data.content)
        .then(() => {
          alert('הודעתך נשלחה בהצלחה');
          reset(); // Reset the form
        })
        .catch((error) => {
          console.error(error);
          alert('ארעה שגיאה, אנא פנה אלינו בווטסאפ, בטלפון או באימייל');
        })
        .finally(() => {
          setIsButtonDisabled(false);
        });
    } else {
      alert(`אנא מלאו את כל הפרטים`);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.formImageMargin}>
        <div className={styles.formImageHead}>
          <img src='/assets/images/get_in_touch.png' className={styles.bannerImg} />
          <div className={styles.formBlock}>
            <div className={styles.contactUsForm}>
              <h3>צרו קשר</h3>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId='formBasicName'>
                  <Form.Control
                    className={styles.contactName}
                    type='text'
                    name='name'
                    dir='rtl'
                    ref={register}
                    placeholder='שם מלא'
                  />
                </Form.Group>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Control
                    className={styles.contactEmail}
                    type='email'
                    name='mail'
                    dir='rtl'
                    ref={register}
                    placeholder='אימייל'
                  />
                </Form.Group>
                <Form.Group controlId='exampleForm.ControlTextarea1'>
                  <Form.Control
                    className={styles.contactReferal}
                    as='textarea'
                    name='content'
                    placeholder='תוכן הפנייה'
                    dir='rtl'
                    ref={register}
                    rows={5}
                  />
                </Form.Group>
                <Button type='submit' className={styles.contactUsButton} disabled={isButtonDisabled}>
                  שליחה
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.headingBlock}>
        <h3>{TEXTS.HEADING}</h3>
        <p dir='rtl'>{TEXTS.PARAGRAPH}</p>
      </div>
      <div className={styles.links}>
        <a
          className={styles.waLink}
          style={{ top: '0%', left: '5%' }}
          target='_blank'
          rel='noopener noreferrer'
          href={`https://api.whatsapp.com/send/?phone=${companyDetails.whatsappNumber}`}
        >
          {SvgIcons.whatsappBlack}
        </a>
        <a
          className={styles.waLink}
          style={{ top: '36%', left: '5%' }}
          target='_blank'
          rel='noopener noreferrer'
          href={companyDetails.facebook}
        >
          {SvgIcons.facebookBlack}
        </a>
        <a
          className={styles.waLink}
          style={{ top: '70%', left: '5%' }}
          target='_blank'
          rel='noopener noreferrer'
          href={companyDetails.linkedIn}
        >
          {SvgIcons.linkedinBlack}
        </a>
        <a
          className={styles.linksWithText}
          target='_blank'
          rel='noopener noreferrer'
          href={companyDetails.navigationLink}
        >
          <h3>{companyDetails.address}</h3>
          {SvgIcons.location}
        </a>
        <a className={styles.linksWithText} href={`mailto:${companyDetails.mail}`} target='_blank'>
          <h3>{companyDetails.mail}</h3>
          {SvgIcons.mail}
        </a>
        <a className={styles.linksWithText} href={`tel:${companyDetails.phone}`}>
          <h3>{companyDetails.phone}</h3>
          {SvgIcons.phone}
        </a>
      </div>
    </div>
  );
};

export default GetInTouchForm;
